import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Modify from '@material-ui/icons/EditOutlined';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import * as store from 'store';
import CustomerDetails from '../components/CustomerDetails';
import Header from '../components/Header';
import MobileOrderDetailSection from '../components/mobile/MobileOrderDetailSection';
import OrderActionButtons from '../components/OrderActionButtons';
import OrderDetailSection from '../components/OrderDetailSection';
import OrderList from '../components/OrderList';
import ProductsTable from 'components/OrderProducts';
import { postOrderToNode } from 'api';
import { useMutation } from 'react-query';
import { createToastMessage } from 'components/toast';
import { useModalContext } from 'contexts/ModalContext';
import GifLoader from 'components/Modals/GiffLoader';
import TransactionStatusModal from 'components/Modals/TransactionStatus';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      height: 45,
      margin: theme.spacing(1),
      borderRadius: theme.spacing(1),
      fontWeight: 450,
    },

    wrapper: {
      float: 'right',
      margin: theme.spacing(3),
      marginLeft: 'auto',
    },

    backButton: {
      color: '#21212190',
      background: '#BDBDBD70',
      border: 'none',
    },

    primaryButton: {
      color: '#FFFFFF',
      background: '#57A946',
      border: 'none',
    },

    colorGreen: {
      color: 'white',
      background: theme.palette.success.dark,
      border: 'none',
      '&:hover': {
        color: theme.palette.success.dark,
        border: `1px solid ${theme.palette.success.dark}`,
        transform: 'scale(1.0)',
      },
    },

    colorBlue: {
      color: 'white',
      background: theme.palette.info.dark,
      border: 'none',
      '&:hover': {
        color: theme.palette.info.dark,
        border: `1px solid ${theme.palette.info.dark}`,
        transform: 'scale(1.0)',
      },
    },

    colorOrange: {
      color: 'white',
      background: theme.palette.warning.dark,
      border: 'none',
      '&:hover': {
        color: theme.palette.warning.dark,
        border: `1px solid ${theme.palette.warning.dark}`,
        transform: 'scale(1.0)',
      },
    },

    colorRed: {
      color: 'white',
      background: theme.palette.error.dark,
      border: 'none',
      '&:hover': {
        color: theme.palette.error.dark,
        border: `1px solid ${theme.palette.error.dark}`,
        transform: 'scale(1.0)',
      },
    },
    disabled: {
      color: theme.palette.text.disabled,
      backgroundColor: theme.palette.action.disabled,
    },
  })
);

interface ParamTypes {
  orderID: string;
}

enum SpotlightPermission {
  DELETE_ORDER = 'delete_order',
  EDIT_ORDER = 'edit_order',
  ASSIGN_AGENT = 'assign_agent',
  CREATE_PICUP = 'create_picup',
  SPOTLIGHT = 'spotlight',
  VENDOR_SWITCH = 'vendor_switch',
  VENDOR_TOGGLE = 'vendor_toggle',
  ORDER_WEBHOOK = 'order_webhook',
  HOLD_ORDER = 'hold_order',
  UPDATE_PRICE = 'update_price',
  SEE_ALL = 'see_all',
  FILTER_DATE = 'filter_date',
  FILTER_SHOPS = 'filter_shops',
  AUTO_ASSIGN = 'auto_assign',
  ALL_SHOPS = 'all_shops',
  RESET_ORDER = 'reset_order',
}
interface IState {
  externalOrderID: string;
  shopper: string;
  isCompleted: string;
  customerType: string;
  orderTypeID: string;
  shopName: string;
  startTime: string;
  shoppingCompleted: string;
  deliveryStarted: string;
  deliveryCompleted: string;
  agentID: string;
  driver: string;
  firstItemPickedTime: null | string;
}

const Orders: React.FC = () => {
  const location = useLocation();
  let { orderID } = useParams<ParamTypes>();
  const externalOrderID = (location.state as IState)?.externalOrderID;
  const shopper = (location.state as IState)?.shopper;
  const isCompleted = (location.state as IState)?.isCompleted;
  const customerType = (location.state as IState)?.customerType;
  const orderTypeID = (location.state as IState)?.orderTypeID;
  const shopName = (location.state as IState)?.shopName;
  const startTime = (location.state as IState)?.startTime;
  const shoppingCompleted = (location.state as IState)?.shoppingCompleted;
  const deliveryStarted = (location.state as IState)?.deliveryStarted;
  const deliveryCompleted = (location.state as IState)?.deliveryCompleted;
  const agentID = (location.state as IState)?.agentID;
  const driver = (location.state as IState)?.driver;
  const firstItemPickedTime = (location.state as IState)?.firstItemPickedTime;

  const [permissions, setPermissions] = useState<string[]>([]);
  const [isModifying, setIsModifying] = useState(false);
  const [transactionSuccess, setTransactionSuccess] = useState(true);
  const { setEditedProducts, setLoaderModal, setTransactionStatusModal, setTotalRefund } = useModalContext();

  const classes = useStyles();
  const history = useHistory();

  const accessToken = store.get('spotlightAccessToken');
  const postOrderToNodeMutation = useMutation(postOrderToNode);

  useEffect(() => {
    const getPermissions = async () => {
      const userPermissions: string[] = store.get('permissions');

      if (userPermissions) setPermissions(userPermissions);
    };
    getPermissions();
  }, []);

  const refundOrder = async () => {
    setLoaderModal(true);
    if (accessToken) {
      postOrderToNodeMutation.mutate(
        { orderID, accessToken },
        {
          onSuccess: (data) => {
            createToastMessage({ message: data.data.message, error: false });
            setEditedProducts([]);
            setLoaderModal(false);
            setTransactionSuccess(true);
            setTransactionStatusModal(true);
          },
          onError: (error) => {
            createToastMessage({ message: error, error: true });
            setEditedProducts([]);
            setLoaderModal(false);
            setTransactionSuccess(false);
            setTransactionStatusModal(true);
          },
        }
      );
    }
  };

  const onClickModifyOrder = () => {
    setIsModifying(true);
  };

  return (
    <React.Fragment>
      <Header />
      <Container style={{ paddingTop: '20px', height: '100%' }} maxWidth={false}>
        {isModifying ? (
          <Grid item xs={12} sm={12} md={12}>
            <Button
              variant="contained"
              startIcon={<ArrowBackIosIcon />}
              className={`${classes.button} ${classes.backButton}`}
              onClick={() => history.push('/orders')}
              size="large"
            >
              Back
            </Button>
            <Grid container spacing={3} justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={12} md={6} style={{ marginTop: '32px', marginBottom: '32px' }}>
                <h3 style={{ margin: '0', padding: '0' }}>Edit Order</h3>
                <p style={{ margin: '8px 0', padding: '0' }}>Order {orderID}</p>
              </Grid>

              <Grid item xs={12} sm={12} md="auto">
                <Button
                  variant="contained"
                  startIcon={<Modify />}
                  className={`${classes.button} ${classes.primaryButton}`}
                  onClick={refundOrder}
                  size="large"
                >
                  Reconcile order
                </Button>
              </Grid>
            </Grid>
            <ProductsTable orderID={orderID} orderTypeID={orderTypeID} isCompleted={isCompleted} />
          </Grid>
        ) : (
          <Grid container spacing={3}>
            {permissions?.includes(SpotlightPermission.EDIT_ORDER) &&
              permissions?.includes(SpotlightPermission.ASSIGN_AGENT) && (
                <Grid item xs={12} sm={12}>
                  <OrderActionButtons shopper={shopper} orderID={orderID} onModifyOrder={onClickModifyOrder} />
                </Grid>
              )}
            <Grid container direction="column" style={{ height: '100%' }} item xs={12} sm={8} md={4}>
              {permissions?.includes(SpotlightPermission.EDIT_ORDER) &&
                permissions?.includes(SpotlightPermission.ASSIGN_AGENT) && (
                  <CustomerDetails customerType={customerType} orderID={orderID} />
                )}

              {permissions?.includes(SpotlightPermission.EDIT_ORDER) &&
              permissions?.includes(SpotlightPermission.ASSIGN_AGENT) ? (
                <OrderDetailSection
                  orderTypeID={orderTypeID}
                  isCompleted={isCompleted}
                  orderID={orderID}
                  externalOrderID={externalOrderID}
                  shopName={shopName}
                  startTime={startTime}
                  shoppingCompleted={shoppingCompleted}
                  deliveryStarted={deliveryStarted}
                  deliveryCompleted={deliveryCompleted}
                  agentID={agentID}
                  shopper={shopper}
                  driver={driver}
                  firstItemPickedTime={firstItemPickedTime}
                />
              ) : (
                <MobileOrderDetailSection
                  orderTypeID={orderTypeID}
                  isCompleted={isCompleted}
                  orderID={orderID}
                  externalOrderID={externalOrderID}
                  shopName={shopName}
                  startTime={startTime}
                  shoppingCompleted={shoppingCompleted}
                  deliveryStarted={deliveryStarted}
                  deliveryCompleted={deliveryCompleted}
                  agentID={agentID}
                  customerType={customerType}
                  shopper={shopper}
                  driver={driver}
                  firstItemPickedTime={firstItemPickedTime}
                />
              )}
            </Grid>
            {permissions?.includes(SpotlightPermission.EDIT_ORDER) &&
              permissions?.includes(SpotlightPermission.ASSIGN_AGENT) && (
                <Grid item xs={12} sm={12} md={8}>
                  <OrderList orderID={orderID} orderTypeID={orderTypeID} isCompleted={isCompleted} />
                </Grid>
              )}
          </Grid>
        )}
      </Container>
      <GifLoader />
      <TransactionStatusModal
        onClose={() => {
          setTransactionStatusModal(false);
          setTotalRefund(0);
        }}
        status={transactionSuccess === true ? 'success' : 'error'}
      />
    </React.Fragment>
  );
};

export default Orders;
