import axios from 'axios';
import moment from 'moment';
import { Order } from '../types';
import { OrderStatus } from '../types/enums';

declare interface Product {
  productID: number;
  name: string;
  price: number;
  status: number;
  imageURL: string;
  orderQuantity: number;
  suppliedQuantity: number;
  substitutionName: string;
  substitutionID: number;
  substitutionQuantity: number;
  substitutionPrice: number;
  substitutionImageURL?: string;
  vendor: string;
  vendorID?: number;
  note?: string;
  total?: number;
  sku: string;
}

enum ShopperOrderProductStatus {
  NOT_DONE = 0,
  COMPLETED = 1,
  CUSTOMER_SUB = 2,
  REFUND = 3,
  SHOPPER_SUB = 4,
  PARTIAL = 5,
  WAITING = 6,
}

export const getUrlWithQueryParams = (url: string, params: any) => {
  let completeURL = new URL(url);
  Object.keys(params).forEach((key) => completeURL.searchParams.append(key, params[key]));
  return completeURL;
};

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SLS_API_ENDPOINT,
  headers: {
    'Content-type': 'application/json',
  },
});

export const groupOrders = (orders: any) => {
  let totalOrders = 0;
  let totalCompleted = 0;
  let notStarted = 0;
  let runningLate = 0;
  let inProgress = 0;
  let completedLate = 0;

  if (orders && orders.length > 0) {
    totalOrders = orders.length;
    for (const order of orders) {
      if (order.original.status === 2) {
        notStarted++;
      }
      if (order.original.status === 1) {
        totalCompleted++;
      }
      if (order.original.status === 3) {
        inProgress++;
      }
      if (order?.original?.runningLate) {
        runningLate++;
      }
      if (order?.original?.completedLate) {
        completedLate++;
      }
    }
  }

  return { totalOrders, totalCompleted, notStarted, runningLate, completedLate, inProgress };
};

export const parseImage = (vendor: string) => {
  const trimmed = vendor.trim();
  const imageUrl = `https://onecart.s3-eu-west-1.amazonaws.com/website/logos/${trimmed}.png`;
  return imageUrl;
};

export function mapProductFulfillment(products: Product[], vendorName: string) {
  let foundItems: Product[] = [],
    outOfStockItems: Product[] = [],
    substitutedItems: Product[] = [],
    partialItems: Product[] = [],
    foundTotal = 0,
    substitutedTotal = 0,
    partialTotal = 0,
    outOfStockTotal = 0,
    vendorCredit = 0;
  products.forEach((product) => {
    if (product.vendor === vendorName) {
      switch (product.status) {
        case ShopperOrderProductStatus.COMPLETED:
          foundItems.push(product);
          foundTotal += product.orderQuantity * product.price;
          product.total = product.suppliedQuantity * product.price;
          break;
        case ShopperOrderProductStatus.REFUND:
        case ShopperOrderProductStatus.NOT_DONE:
          outOfStockTotal += product.orderQuantity * product.price;
          outOfStockItems.push(product);
          vendorCredit += product.orderQuantity * product.price;
          product.total = product.suppliedQuantity * product.price;
          break;
        case ShopperOrderProductStatus.CUSTOMER_SUB:
        case ShopperOrderProductStatus.SHOPPER_SUB:
          substitutedItems.push(product);
          substitutedTotal += product.substitutionQuantity * product.substitutionPrice;
          vendorCredit +=
            product.orderQuantity * product.price - product.substitutionQuantity * product.substitutionPrice;
          product.total = product.substitutionQuantity * product.substitutionPrice;
          break;
        case ShopperOrderProductStatus.PARTIAL:
          partialItems.push(product);
          partialTotal += product.suppliedQuantity * product.price;
          vendorCredit += product.orderQuantity * product.price - product.suppliedQuantity * product.price;
          product.total = product.suppliedQuantity * product.price;
          break;
        default:
          break;
      }
    }
  });

  return {
    foundItems,
    outOfStockItems,
    substitutedItems,
    partialItems,
    foundTotal,
    substitutedTotal,
    partialTotal,
    outOfStockTotal,
    vendorCredit,
  };
}

export function isRunningLate(order: Order) {
  if (!order || !order?.status) return null;

  if ([OrderStatus.LATE].includes(order.status)) return true;

  if (
    [OrderStatus.COMPLETED, OrderStatus.CANCELLED, OrderStatus.NO_ITEMS_FOUND, OrderStatus.ON_HOLD].includes(
      order.status
    )
  )
    return false;

  const deliveryDate = moment(`${order.deliveryDate}T${order.deliveryTo}:00+0200`);

  return moment().isSameOrAfter(deliveryDate);
}

export function isCompletedLate(order: Order) {
  if (!order || !order?.status) return null;

  if (order.status !== OrderStatus.COMPLETED) return false;

  const deliveryDate = `${order.deliveryCompleted}+0200`.replace(' ', 'T');

  const expectedDeliveryDate = moment(`${order.deliveryDate}T${order.deliveryTo}:00+0200`);

  return moment(deliveryDate).isAfter(expectedDeliveryDate);
}
export function hasPickupOrDropOffException(order: Order) {
  if (!order || !order?.status) return null;

  if (order.status !== OrderStatus.COMPLETED) return false;

  if (order.pickup_exception || order.drop_off_exception) return true;
}
export const padLeft = (value: string, padChar: string, padLength: number): string => {
  // get the pad character
  padChar = padChar[0] || ' ';
  // add the padChar until the length of value is the desired length or bigger
  while (value.length < padLength) {
    value = padChar + value;
  }
  // return the padded result
  return value;
};

export const transformSearchResults = (results: any[]): Product[] => {
  return results.map((result) => ({
    productID: result.productID || 0,
    name: result.objectID || 'Unnamed Product',
    price: result.price || 0,
    status: result.status || 0,
    imageURL: result.ImageURL || '',
    sku: result.sku || '',
    orderQuantity: result.orderQuantity || 0,
    suppliedQuantity: result.suppliedQuantity || 0,
    substitutionName: result.substitutionName || '',
    substitutionID: result.substitutionID || 0,
    substitutionQuantity: result.substitutionQuantity || 0,
    substitutionPrice: result.substitutionPrice || 0,
    substitutionImageURL: result.substitutionImageURL || '',
    vendor: result.vendor || '',
    vendorID: result.vendorID || 0,
    note: result.note || '',
    total: result.total || 0,
  }));
};

export const getWeightedBarcodeParts = (
  barcode: string
): {
  gs1prefix: string;
  prefix: string;
  prefixType: string;
  productIdentifier: string;
  priceVerifierDigit: string;
  price: number;
  checksum: string;
  embedID?: string;
  expiry?: string;
  rcn: string;
  productCode: string;
  error?: string;
  barcode?: string;
} => {
  const is4DigitPrice = barcode.match(/^(\(?01\)?)?(0?2)(9)/);
  const parts = barcode.match(
    is4DigitPrice
      ? /^(\(?01\)?)?(0?2)([0-9])(?:([0-9]{5})([0-9])([0-9]{4}))([0-9]{1})(?:(\(?15\)?)([0-9]{6}))?$/
      : /^(\(?01\)?)?(0?2)([0-9])(?:([0-9]{4})([0-9])([0-9]{5}))([0-9]{1})(?:(\(?15\)?)([0-9]{6}))?$/
  );
  const errorObj = {
    gs1prefix: '',
    prefix: '',
    prefixType: '',
    productIdentifier: '',
    priceVerifierDigit: '',
    price: 0,
    checksum: '',
    embedID: undefined,
    expiry: undefined,
    rcn: '',
    productCode: '',
    error: 'Invalid barcode provided',
    barcode: barcode,
  };

  if (!parts) {
    return errorObj;
  }
  const barcodeParts = {
    gs1prefix: parts[1],
    prefix: parts[2],
    prefixType: parts[3],
    productIdentifier: parts[4],
    priceVerifierDigit: parts[5],
    price: Number(parts[6]) / 100.0,
    checksum: parts[7],
    embedID: parts[8],
    expiry: parts[9] ? `20${parts[9]?.substr(0, 2)}-${parts[9]?.substr(2, 2)}-${parts[9]?.substr(4, 2)}` : undefined,
    rcn: '',
    productCode: '',
    error: '',
    barcode: '',
  };
  if (!barcodeParts.checksum) {
    return errorObj;
  }
  // save rcn and productCode (the entire RCN-13 barcode without the price but with recalculated checksum digit)
  barcodeParts.rcn = Number(
    barcodeParts.prefix +
      barcodeParts.prefixType +
      barcodeParts.productIdentifier +
      barcodeParts.priceVerifierDigit +
      parts[6] +
      barcodeParts.checksum
  ).toString();
  // calculate checksum of productCode
  const productCodeNoChecksum = Number(
    barcodeParts.prefix +
      barcodeParts.prefixType +
      barcodeParts.productIdentifier +
      '0' +
      padLeft('0', '0', parts[6].length ?? 5)
  ).toString();
  let oddSum = 0;
  let evenSum = 0;
  // add all digits in odd positions (index starts at 0, therefore it's even here)
  for (let i = 0; i < productCodeNoChecksum.length; ++i) {
    if (i % 2 === 0) oddSum += Number(productCodeNoChecksum[i]);
    // add the character at odd position (even index)
    else evenSum += Number(productCodeNoChecksum[i]);
  }
  const checksumCalc = 10 - ((oddSum + 3 * evenSum) % 10); // 10 - (3E + O) mod 10 (if = 10, use 0)
  const checksum = checksumCalc > 9 ? 0 : checksumCalc;
  barcodeParts.productCode = productCodeNoChecksum + `${checksum}`;
  return barcodeParts;
};
