import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Typography,
  IconButton,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useModalContext } from 'contexts/ModalContext';
import { Product } from '../../types';
import SubstituteSearchInput from '../SearchSubstitute';
import { markProductFound, markProductNotFound, markProductSubstituted } from '../../api';
import * as store from 'store';
import { createToastMessage } from '../toast';
import { useMutation } from 'react-query';

const useStyles = makeStyles((theme) => ({
  button: {
    height: 45,
    margin: theme.spacing(1),
    borderRadius: theme.spacing(1),
    fontWeight: 450,
  },
  primaryButton: {
    color: '#FFF',
    background: '#57A946',
    border: 'none',
  },

  secondaryActionButton: {
    color: '#21212190',
    background: 'transparent',
    border: '1px solid #1212124D',
    borderRadius: '8px',
    padding: '6px 12px',
    cursor: 'pointer',
    '&:hover': {
      background: '#f0f0f0',
    },
  },
  addBarcodeButton: {
    textDecoration: 'underline',
    color: 'gray',
    padding: '6px 12px',
    fontWeight: 'normal',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      background: 'transparent',
    },
  },
  dialogTitle: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  headerContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' },
  heading: {
    margin: '0',
    fontSize: ' 16px',
  },
  weighted: {
    color: '#FF4181',
    margin: '0',
    fontSize: ' 10px',
  },

  nonWeighted: {
    color: '#43B09D',
    margin: '0',
    fontSize: ' 10px',
  },
}));

interface ModifyOrderProps {
  orderID: string;
  onSuccess: () => void;
}

const ModifyOrderModal: FunctionComponent<ModifyOrderProps> = ({ orderID, onSuccess }) => {
  const {
    isModalOpen,
    setModalOpen,
    selectedProduct,
    setSelectedProduct,
    setEditedProducts,
    editedProducts,
    isWeighted,
    setTotalRefund,
  } = useModalContext();
  const [showReplacementDropdown, setShowReplacementDropdown] = useState(true);
  const [newQuantity, setnewQuantity] = useState(selectedProduct?.substitutionQuantity?.toString() || '');
  const [selectedSubstitute, setSelectedSubstitute] = useState<Product | null>(null);
  const [barcodes, setBarcodes] = useState<string[]>(['']);
  const [substituteQuantity, setSubstituteQuantity] = useState<string>('');
  const [status, setStatus] = useState<number | string>('');
  const [loading, setLoading] = useState(false);
  const [amountToRefund, setAmountToRefund] = useState<number>(0);

  const classes = useStyles();
  const accessToken = store.get('spotlightAccessToken');

  const productFoundMutation = useMutation(markProductFound);
  const productSubstitutedMutation = useMutation(markProductSubstituted);
  const productNotFoundMutation = useMutation(markProductNotFound);

  const handleSubstituteSelect = (product: Product | null) => {
    setSelectedSubstitute(product);
  };

  const handleClose = () => {
    setModalOpen(false);
    setSelectedProduct(null);
    setShowReplacementDropdown(true);
    setStatus('');
  };

  const calculateRefund = (newStatus: number | string) => {
    if (!selectedProduct) return;

    const productPrice = selectedProduct.price;
    const initialQuantity = Number(selectedProduct.orderQuantity);
    const productQuantity = Number(newQuantity) || 0;
    const substitutePrice = selectedSubstitute?.price || 0;
    const substituteQty = isWeighted ? selectedSubstitute?.barcode?.length || 0 : Number(substituteQuantity) || 0;
    const baseRefundAmount = productPrice * initialQuantity;

    const calculateRefundAmount = (price: number, initialQty: number, finalQty: number) => {
      return Math.max(price * (initialQty - finalQty), 0);
    };

    if (newStatus === 3) {
      const refundAmount = baseRefundAmount - substitutePrice * substituteQty;
      setAmountToRefund(Math.max(refundAmount, 0));
      return;
    }

    const refundAmount = calculateRefundAmount(productPrice, productQuantity, substituteQty);

    if (isWeighted) {
      switch (newStatus) {
        case 1:
          setAmountToRefund(0); // Fully found product
          break;
        case 2:
          setAmountToRefund(
            barcodes.length > 0
              ? calculateRefundAmount(productPrice, initialQuantity, barcodes.length)
              : baseRefundAmount
          ); // Partially found product
          break;
        default:
          setAmountToRefund(baseRefundAmount);
      }
    } else {
      if (selectedSubstitute) {
        setAmountToRefund(refundAmount);
      } else {
        switch (newStatus) {
          case 1: // Fully found product
            setAmountToRefund(
              initialQuantity === productQuantity
                ? 0
                : calculateRefundAmount(productPrice, initialQuantity, productQuantity)
            );
            break;
          case 2: // Partially found product
            setAmountToRefund(
              productQuantity > 0 ? calculateRefundAmount(productPrice, initialQuantity, productQuantity) : 0
            );
            break;
          default:
            setAmountToRefund(baseRefundAmount);
        }
      }
    }
  };

  useEffect(() => {
    calculateRefund(status);
  }, [newQuantity, substituteQuantity, selectedSubstitute, status]); // eslint-disable-line react-hooks/exhaustive-deps

  const resetForm = () => {
    setnewQuantity('');
    setBarcodes(['']);
    setSubstituteQuantity('');
    setSelectedSubstitute(null);
    setStatus('');
    setAmountToRefund(0);
    setShowReplacementDropdown(true);
  };

  const handleSave = async () => {
    setTotalRefund((prevTotalRefund) => prevTotalRefund + amountToRefund);
    setLoading(true);
    if (!selectedProduct) return;

    const { productID, status } = selectedProduct;

    try {
      if (status === 1 || status === 2) {
        const quantity = isWeighted ? barcodes.length : Number(newQuantity) || 0;
        setSelectedProduct((prevProduct: Product) => ({
          ...prevProduct,
          orderQuantity: quantity,
        }));

        productFoundMutation.mutate(
          {
            accessToken,
            selectedProduct,
            quantity,
            orderID,
            barcodes: isWeighted ? barcodes : [],
            forceBarcodes: false,
          },
          {
            onSuccess: (data) => {
              onSuccess();
              setLoading(false);
              resetForm();
              handleClose();
            },
            onError: (error) => {
              setLoading(false);
              createToastMessage({ message: error, error: true });
              resetForm();
              handleClose();
            },
          }
        );

        if (!editedProducts.includes(productID)) {
          setEditedProducts([...editedProducts, productID]);
        }
      } else if (status === 3 && selectedSubstitute) {
        productSubstitutedMutation.mutate(
          {
            accessToken,
            orderID,
            selectedProduct,
            shopperSuggested: true,
            subProductID: selectedSubstitute.ID,
            subQtyFound: Number(substituteQuantity),
            subBarcode: Array.isArray(selectedSubstitute.barcode)
              ? selectedSubstitute.barcode
              : [selectedSubstitute.barcode],
            forceBarcodes: true,
            barcodes: isWeighted ? barcodes : [],
            qtyFound: 0,
          },
          {
            onSuccess: (data) => {
              onSuccess();
              setLoading(false);
              resetForm();
              handleClose();
            },
            onError: (error) => {
              createToastMessage({ message: error, error: true });
              setSelectedSubstitute(null);
              setLoading(false);
              resetForm();
              handleClose();
            },
          }
        );

        if (!editedProducts.includes(productID)) {
          setEditedProducts([...editedProducts, productID]);
        }
      } else if (status === 3) {
        productNotFoundMutation.mutate(
          {
            accessToken,
            selectedProduct,
            orderID,
          },
          {
            onSuccess: (data) => {
              onSuccess();
              setLoading(false);
              resetForm();
              handleClose();
            },
            onError: (error) => {
              createToastMessage({ message: error, error: true });
              setLoading(false);
              resetForm();
              handleClose();
            },
          }
        );

        if (!editedProducts.includes(productID)) {
          setEditedProducts([...editedProducts, productID]);
        }
      }
      setAmountToRefund(0);
      setSelectedSubstitute(null);
    } catch (error) {
      setAmountToRefund(0);
    }
  };

  const handleStatusChange = (status: number) => {
    setAmountToRefund(0);
    setStatus(status);
    setSelectedProduct((prevProduct: Product) => ({ ...prevProduct, status }));
    setShowReplacementDropdown(status === 3);

    if (status !== 3 && isWeighted && selectedProduct?.sku) {
      setBarcodes(barcodes);
    }
    calculateRefund(status);
  };

  const handlenewQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setnewQuantity(value);
    const numericValue = Number(value);
    if (!isNaN(numericValue)) {
      setSelectedProduct((prevProduct: Product) => ({
        ...prevProduct,
        substitutionQuantity: numericValue,
      }));
    }

    /*
      if the product is not a weighted item, marked not found and a substitute is selected
      then the entered quantity should the the quantity for substitute items
     */

    if (!isWeighted && showReplacementDropdown && selectedSubstitute) {
      setSubstituteQuantity(value);
    }
    calculateRefund(status);
  };

  const addBarcodeField = () => {
    setBarcodes([...barcodes, '']);
  };

  const handleBarcodeChange = (index: number, value: string) => {
    const updatedBarcodes = [...barcodes];
    updatedBarcodes[index] = value;
    setBarcodes(updatedBarcodes);
  };

  const formattedDispalyQuantity =
    selectedProduct?.orderQuantity !== undefined ? `${newQuantity}/${selectedProduct.orderQuantity}` : `0/0`;

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      PaperProps={{ style: { width: '392px', padding: '16px', borderRadius: '12px' } }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.headerContainer}>
          <p className={classes.heading}>Edit Product</p>
          {isWeighted ? (
            <p className={classes.weighted}>Weighted Item</p>
          ) : (
            <p className={classes.nonWeighted}>Non-Weighted Items</p>
          )}
        </div>

        <IconButton aria-label="close" onClick={handleClose} style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
            {selectedProduct?.imageURL && (
              <img
                src={selectedProduct.imageURL}
                alt={selectedProduct.name}
                style={{ width: 18, height: 18, objectFit: 'cover', marginRight: 8 }}
              />
            )}
            <Typography variant="body1" style={{ fontSize: '12px', fontWeight: 300 }}>
              {selectedProduct?.name || 'No name'}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography variant="body1" style={{ fontSize: '10px', fontWeight: 600 }}>
              {selectedProduct?.price ? `R${selectedProduct.price.toFixed(2)}` : 'No price'}
            </Typography>
          </Grid>
        </Grid>

        <Divider style={{ margin: '6px 0' }} />
        <InputLabel style={{ color: 'black', fontSize: '12px', fontWeight: '400' }} shrink={false}>
          What is the correct status of the product you wish to edit?
        </InputLabel>
        <Select
          value={status}
          onChange={(e) => handleStatusChange(e.target.value as number)}
          style={{ border: '1px solid black', borderRadius: 8, padding: '0.5rem', width: '100%', marginTop: '8px' }}
          MenuProps={{ PaperProps: { style: { borderRadius: 8, marginTop: '2.8rem' } } }}
          displayEmpty
        >
          <MenuItem value="" disabled>
            Select product status
          </MenuItem>
          <MenuItem value={1}>Product Found</MenuItem>
          <MenuItem value={2}>Product Partially Found</MenuItem>
          <MenuItem value={3}>Product Not Found</MenuItem>
        </Select>

        {showReplacementDropdown && selectedProduct && (
          <SubstituteSearchInput
            productID={String(selectedProduct.productID)}
            onSubstituteSelect={handleSubstituteSelect}
          />
        )}

        {isWeighted ? (
          <>
            <InputLabel style={{ color: 'black', fontSize: '12px', fontWeight: '400', marginTop: '16px' }}>
              Enter Barcodes
            </InputLabel>
            {barcodes.map((barcode, index) => (
              <TextField
                key={index}
                fullWidth
                margin="dense"
                placeholder={`Barcode ${index + 1}`}
                value={barcode}
                onChange={(e) => handleBarcodeChange(index, e.target.value)}
                InputProps={{
                  style: { border: '1px solid black', borderRadius: 8, padding: '10px 12px' },
                  disableUnderline: true,
                }}
                InputLabelProps={{ style: { fontSize: '12px', color: 'black' } }}
              />
            ))}

            <Button
              onClick={addBarcodeField}
              variant="text"
              disabled={barcodes.some((barcode) => barcode === '')}
              className={classes.addBarcodeButton}
            >
              Add another barcode
            </Button>
          </>
        ) : (
          <>
            {showReplacementDropdown && selectedSubstitute ? (
              <>
                <InputLabel style={{ color: 'black', fontSize: '12px', fontWeight: '400', marginTop: '16px' }}>
                  Enter Substitute Quantity
                </InputLabel>
                <TextField
                  fullWidth
                  margin="dense"
                  value={newQuantity}
                  onChange={handlenewQuantityChange}
                  InputProps={{
                    style: { border: '1px solid black', borderRadius: 8, padding: '10px 12px' },
                    disableUnderline: true,
                  }}
                  InputLabelProps={{ style: { fontSize: '12px', color: 'black' } }}
                />
              </>
            ) : (
              <>
                <InputLabel style={{ color: 'black', fontSize: '12px', fontWeight: '400', marginTop: '16px' }}>
                  Edit quantity {formattedDispalyQuantity}
                </InputLabel>
                <TextField
                  fullWidth
                  margin="dense"
                  value={newQuantity}
                  onChange={handlenewQuantityChange}
                  InputProps={{
                    style: { border: '1px solid black', borderRadius: 8, padding: '10px 12px' },
                    disableUnderline: true,
                  }}
                  InputLabelProps={{ style: { fontSize: '12px', color: 'black' } }}
                />
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions style={{ width: '90%', padding: '16px 18px' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              onClick={handleClose}
              fullWidth
              variant="text"
              className={`${classes.button} ${classes.secondaryActionButton}`}
            >
              Discard changes
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={handleSave}
              disabled={status === '' || loading}
              fullWidth
              variant="contained"
              className={`${classes.button} ${classes.primaryButton}`}
            >
              {loading ? 'Saving changes..' : `Save changes`}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModifyOrderModal;
