import React, { useImperativeHandle, useMemo } from 'react';
import { useTable, useFilters, useGlobalFilter, useSortBy } from 'react-table';
import { Button, Paper, Theme, createStyles, makeStyles } from '@material-ui/core';
import VirtualizedProductsTable from './VirtualProductsTable';
import { useQuery } from 'react-query';
import * as store from 'store';
import { getShoppingList } from '../api';
import ModifyOrderModal from './Modals/ModifyOrderModal';
import { useModalContext } from 'contexts/ModalContext';
import { Column, Product, TableProps, Vendor } from 'types';
import { getWeightedBarcodeParts } from 'utils/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '69vh',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '69vh',
      },
    },
    addBarcodeButton: {
      textDecoration: 'underline',
      color: 'gray',
      padding: '6px 12px',
      fontWeight: 'normal',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none',
        background: 'transparent',
      },
    },
  })
);

const ProductsTable = React.forwardRef((props: TableProps, ref: any) => {
  const { orderID } = props;
  const { setModalOpen, setSelectedProduct, setIsWeighted } = useModalContext();

  const classes = useStyles();

  const accessToken = store.get('spotlightAccessToken');
  const { data, refetch } = useQuery(['receiptShopping', accessToken, orderID], () =>
    getShoppingList({ accessToken, orderID })
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const vendors = data?.data?.details || [];

  const allProducts = useMemo(() => vendors.flatMap((vendor: Vendor) => vendor.products), [vendors]);

  const ProductColumns: Column[] = [
    { Header: 'Product Details', accessor: 'name' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Price', accessor: 'price' },
    { Header: 'Quantity', accessor: 'orderQuantity' },
    {
      Header: 'Action',
      accessor: 'vendor',
      Cell: ({ row }: { row: any }) => (
        <Button onClick={() => handleEdit(row.original)} variant="text" className={classes.addBarcodeButton}>
          Edit product
        </Button>
      ),
    },
  ];

  const handleEdit = (product: Product) => {
    const checkIsWeighted = (sku: string | undefined) => {
      if (sku) {
        const barcodeParts = getWeightedBarcodeParts(sku);
        return barcodeParts.error === '' ? true : false;
      }
      return false;
    };
    const isWeighted = checkIsWeighted(product.sku);
    setSelectedProduct(product);
    setModalOpen(true);
    setIsWeighted(isWeighted);
  };

  const columns = useMemo(() => ProductColumns, []); // eslint-disable-line react-hooks/exhaustive-deps

  const instance = useTable(
    {
      columns,
      data: allProducts,
      autoResetFilters: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  useImperativeHandle(ref, () => instance);

  return (
    <Paper className={classes.root}>
      <VirtualizedProductsTable instance={instance} />
      <ModifyOrderModal orderID={orderID} onSuccess={() => refetch()} />
    </Paper>
  );
});

export default ProductsTable;
